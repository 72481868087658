<template>
  <div>
    <div class="sidebar">
      <div v-for="(item, index) in menu" :key="index">
        <div v-if="item.show" @click="event => tap(index)" class="sidebar_item"
          :style="selected == index ? 'background:#206bc4' : ''">
          <div style="padding-top: 2px;">{{ item.text }}</div>
        </div>
      </div>
    </div>
    <div style="padding-left: 145px;padding-top: 70px;height: 100vh;width: 100%;">
      <component :callParentMethod="parentMethod" :parentData="parentData" v-if="menu[selected].show"
        :is="menu[selected].com"></component>
    </div>
    <b-modal id="welcome-modal" title="后台管理系统" hide-footer>
      <span style="margin: 30px 0 ">欢迎登录到后台管理系统，祝您用的愉快。</span>
      <b-button class="mt-3" style="height: 35px;" block @click="$bvModal.hide('welcome-modal')">关闭</b-button>
    </b-modal>
  </div>
</template>
<script>
  import Program from '../wrapper/Program.vue'
  import Order from '../wrapper/Order.vue'
  import Merchant from '../wrapper/Merchant.vue'
  import Setting from '../wrapper/Setting.vue'
  import Personnel from '../wrapper/Personnel.vue'
  import Notice from '../wrapper/Notice.vue'
  import User from '../wrapper/User.vue'
  import Account from '../wrapper/Account.vue'
  import App from '../wrapper/App.vue'
  import CallPhone from '../wrapper/CallPhone.vue'
  import Evaluation from '@/wrapper/Evaluation.vue' 
  import TimeManagement from '@/wrapper/TimeManagement.vue' 

  export default {
    components: {
      Order,
      Merchant,
      Setting
    },
    data() {
      return {
        menu: [{
            name: 'Order',
            text: '订单管理',
            com: Order
          },
          {
            name: 'Program',
            text: '直选项目',
            com: Program
          },
          {
            name: 'Merchant',
            text: '商家管理',
            com: Merchant
          },
          {
            name: 'Personnel',
            text: '人员管理',
            com: Personnel
          },
          {
            name: 'TimeManagement',
            text: '时间管理',
            com: TimeManagement
          },
          {
            name: 'Notice',
            text: '通知提醒',
            com: Notice
          },
          {
            name: 'App',
            text: '应用管理',
            com: App
          },
          {
            name: 'User',
            text: '用户管理',
            com: User
          },
          {
            name: 'Evaluation',
            text: '评价管理',
            com: Evaluation
          },
          {
            name: 'Setting',
            text: '店铺设置',
            com: Setting
          },
          {
            name: 'Account',
            text: '账号权限',
            com: Account
          },
          {
            name: 'CallPhone',
            text: '通话管理',
            com: CallPhone
          },

        ],
        selected: 0,
        parentData: {}
      };
    },
    mounted() {
      this.$bvModal.show("welcome-modal")	
      // 组件挂载完成回调，处理逻辑
      this.get('get_permissions', {}).then(({
        permissions
      }) => {
        this.menu.forEach(element => {
          if (permissions === '*') {
            element.show = true
          } else {
            element.show = permissions.indexOf(element.name) !== -1
          }
        })
        this.$forceUpdate()
      })
    },
    methods: {
      tap(index) {
        this.parentData = {  }
        this.selected = index
        console.log(this.selected)
      },
      parentMethod(params) {
        this.selected = 0
        this.parentData = {
          order_id: params.order_id
        }
      }
    }
  }
</script>
<style scoped>
  .sidebar {
    position: fixed;
    left: 0;
    top: 40px;
    background: #293038;
    height: 100%;
    border-right: 1px #e7e7e7 solid;
    padding: 20px 0 0;
    width: 130px;
    overflow: auto;
  }

  .sidebar_item {
    display: block;
    padding: 5px 10px;
    border-bottom: 1px #363b41 solid;
    color: #fff;
    text-decoration: none;
    transition: background 1s;
    font-size: 14px;
    line-height: 22px;
    cursor: pointer;
    margin-bottom: 3px;
    user-select: none;
  }

  .sidebar_item:hover {
    /* background: #206bc4; */
    background: #505a5f;
    color: #fff;
  }
</style>