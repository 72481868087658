<template>
    <div>
        <div class="wrapper">
            <div class="header">
                <div
                    style="display: flex;margin-top: 2px;margin-right: 10px;align-items: center;justify-content: space-between;width: 100%;">
                    <div></div>
                    <div style="display: flex;align-items: center;">
                        <div style="display: flex;margin-top: 2px;">
                          <b-form-select  style="margin-right: 5px;font-size: 12px; align-items: center; padding: 5px;width: 100px; height: 44px;"
 v-model="source" :options="sourceOptions">
                            <template v-slot:first>
                                    <b-form-select-option :value="null" disabled>-- 请选择通话来源 --</b-form-select-option>
                            </template>
                          </b-form-select>
                          <b-datepicker
                            style="margin-right: 5px;font-size: 12px; align-items: center; padding: 5px;width: 140px; height: 44px;"
                            v-model="startDate"
                            label-no-date-selected="请选择开始时间"
                            :date-format-options="{ year: 'numeric', month: 'numeric', day: 'numeric' }"
                            locale="cn"
                          ></b-datepicker>
                          <b-form-datepicker
                            style="margin-right: 5px;font-size: 12px; align-items: center; padding: 5px;width: 140px; height: 44px;"
                            v-model="endDate"
                            label-no-date-selected="请选择结束时间"
                            :date-format-options="{ year: 'numeric', month: 'numeric', day: 'numeric' }"
                            locale="cn"
                          ></b-form-datepicker>
                          <input v-model="searchPhone" type="text"
                                style="border-radius: 3px 0 0 3px;border-right: none;border: 1px solid #cccccc;width: 130px; height: 44px;"
                                placeholder="手机号码">
                            <input v-model="searchOrder" type="text"
                                style="border-radius: 3px 0 0 3px;border-right: none;border: 1px solid #cccccc;width: 130px;margin-left: 5px;margin-right:5px;height: 44px;"
                                placeholder="订单号">
                            <div @click="search" class="mybtn" style="border-radius: 0 3px 3px 0;width: 50px;height: 44px;padding: 5px;align-items: center;display: flex;">搜索</div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="body">
                <div style="padding: 10px;">
                    <Table ref="call_phone_list" :fields="fields" :data="call_phone_list" :searchValue="searchValue" :loadAll="loadAll" @scroll="table_scrollEvent">
                      <template v-slot:order_id="cell">
                            <span style="color: #1f68be;cursor:pointer;" @click="goToOrder(cell.value)">{{ cell.value }}</span>
                        </template>
                        <template v-slot:callerState="cell">
                            {{ callerState[cell.value] || cell.value }}
                        </template>
                        <template v-slot:recUrl="cell">
                          <a :href="cell.value" target="_blank">查看通话记录</a>
                        </template>
                    </Table>

                    <div v-if="showLoading"
                        style="display: flex;align-items: center;justify-content: center;padding: 15px 0;background-color: #fff;font-size: 14px;">
                        获取数据中........
                    </div>

                    <div v-if="call_phone_list.length == 0 && !showLoading"
                        style="display: flex;align-items: center;justify-content: center;padding: 15px 0;background-color: #fff;font-size: 14px;">
                        暂无
                    </div>
                </div>

            </div>

        </div>
    </div>
</template>
<script>
import Table from '../components/Table/Table'


export default {
    name: 'CallPhone',
    components: {
        'Table': Table,
    },
    data() {
        return {
            showLoading: true,
            searchOrder: null,
            searchPhone: null,
            startDate: null,
            endDate: null,
            call_phone_list:[],
            fields: [
                { key: 'source', label: '来源', width: '120px' },
                { key: 'businesses_cooperating_storeName', label: '店铺(用户预约)', width: '120px' },
                { key: 'order_id', label: '订单号', width: '120px' },
                { key: 'admin_name', label: '账号', width: '120px' },
                { key: 'user_name', label: '用户昵称', width: '120px' },
                { key: 'callerNumber', label: '主叫电话', width: '120px' },
                { key: 'calleeNumber', label: '被叫电话', width: '120px' },
                { key: 'displayNumber', label: '小号电话', width: '120px' },
                { key: 'callerState', label: '呼叫状态', width: '120px' },
                { key: 'startCallTime', label: '拨打时间', width: '220px' },
                { key: 'duration', label: '通话时长(秒)', width: '120px' },
                { key: 'recUrl', label: '通话记录' },
            ],
            callerState: {
                0: '正常',
                1: '空号',
                2: '用户忙',
                3: '拒接',
                4: '无人接听',
                5: '无法接通',
                6: '呼叫被拦截',
                7: '关机',
                8: '停机',
                9: '其他',
                10: '主叫挂机',
                11: '被叫挂机',
                12: '主叫放弃',
                13: '小号状态异常',
                14: '订单超期',
                15: '平台异常',
            },
            source: '后台',
            sourceOptions: [
              { value: '后台', text: '后台' },
              { value: '用户预约', text: '用户预约' },
          ]

        };
    },
    mounted() {
        // 组件挂载完成回调，处理逻辑
        var today = new Date();
        var year = today.getFullYear(); // 四位数表示的完整年份（如2021）
        var month = (today.getMonth() + 1).toString().padStart(2, '0'); // 两位数表示的月份（从0开始计算，所以需要加1）
        var day = today.getDate().toString().padStart(2, '0'); // 两位数表示的日期
        this.startDate = `${year}-${month}-${day}`
        this.get_call_phone_list()
    },
    methods: {
        search() {
            this.get_call_phone_list()
        },
        get_call_phone_list() {
          let data = {}
            if (this.searchOrder && this.searchOrder != '') {
                data.order_id = this.searchOrder
            }
            if (this.searchPhone && this.searchPhone != '') {
                data.phone = this.searchPhone
            }
            if (this.startDate) {
                data.start_date = this.startDate + ' 00:00:00'
            }
            if (this.endDate) {
                data.end_date = this.endDate + ' 23:59:59'
            }
            if (this.source) {
              data.source = this.source
            }

            this.showLoading = true

            this.post('call_record', data).then(({ list }) => {
                console.log(":list", list)
                this.call_phone_list = list
                this.loadAll = true
                this.showLoading = false
            })
        },
        goToOrder(id) {
            console.log('跳转到订单详情', id)
            this.$parent.parentMethod({ order_id: id })
        },
    }
}
</script>
<style scoped >
.wrapper {
    color: #333;
    background-color: #fff;
    border-radius: 3px;
    border: 1px solid #dddddd;
}

.header {
    background-color: #f5f5f5;
    border-bottom: 1px solid #dddddd;
    border-top-left-radius: 3px;
    border-top-right-radius: 3px;
    padding: 10px 15px;
    font-size: 12.5px;
    font-family: "Microsoft YaHei", SimSun;
    display: flex;
    justify-content: space-between;
    border-top: 1px solid #dddddd;
}

.bread_crumb_item {
    cursor: pointer;
    border-bottom: 2px solid #fff;
    padding: 10px 15px 8px 15px;
    color: #206bc4;
    font-size: 14px;
    margin-right: 4px;

}

.bread_crumb_item_selected {
    cursor: pointer;
    border-bottom: 2px solid #206bc4;
    padding: 10px 15px 8px 15px;
    border-radius: 5px 5px 0 0;
    color: #fff;
    background: #206bc4;
    font-size: 14px;
    margin-right: 4px;

}

.bread_crumb_item_selected:hover {
    background: #206bc4;
}

.bread_crumb_item:hover {
    background: #eeeeee;
}

.body {}

.tab {
    background-color: white;
    padding: 5px 10px;
    font-size: 11.5px;
    cursor: pointer;
    touch-action: manipulation;
    user-select: none;

}

.tab-select {
    color: white;
    background-color: #1f68be;
}

.tab:hover {
    background: #e6e6e6;
    color: black;
}

.tab:active {
    background: #e6e6e6;
    color: black;
    box-shadow: inset 0 3px 5px rgba(0, 0, 0, .125);
}

.tab-border-left {
    border-left: 1px solid #cccccc;
}

.mybtn {
    background-color: #2c72b0;
    padding: 5px 10px;
    font-size: 11.5px;
    cursor: pointer;
    touch-action: manipulation;
    color: #ffffff;
    user-select: none;

}

.mybtn:hover {
    background-color: #286090;
    color: #ffffff;
}

.mybtn:active {
    background-color: #1d5280;
    box-shadow: inset 0 3px 5px rgba(0, 0, 0, .125);
}


.btn2 {
    background-color: #e8e8e8;
    box-shadow: inset 0 3px 5px rgba(0, 0, 0, .125);
    padding: 5px 10px;
    font-size: 11.5px;
    cursor: pointer;
    touch-action: manipulation;
    border: 1px solid #555555;
    color: #000000;
    border-radius: 3px;
    user-select: none;
}

.btn2:hover {
    background-color: #cccccc;
}

.btn2:active {
    background-color: #aaaaaa;
    box-shadow: inset 0 3px 5px rgba(0, 0, 0, .125);
}



.btn3 {
    background-color: #f0ad4e;
    padding: 8px 17px;
    font-size: 14px;
    cursor: pointer;
    touch-action: manipulation;
    color: #ffffff;
    border-radius: 3px;
}

.btn3:hover {
    background-color: #e09b3c;
    color: #ffffff;
}

input {
    outline: none;
    height: 30px;
    padding: 5px 10px;
    font-size: 12px;
    line-height: 1.5;
    border-radius: 3px;
    border: 1px solid #cccccc;
}

input:focus {
    border: 1px solid #3873c0 !important;
}

.float {
    /* float: left; */
    /* min-width: 25%; */
    height: 100%;
    display: flex;
    padding: 10px 0;
}


.btn5 {
    background-color: #d9534f;
    padding: 5px 10px;
    font-size: 12px;
    cursor: pointer;
    touch-action: manipulation;
    color: #ffffff;
    border-radius: 3px;

}

.btn5:hover {
    background-color: #c9302c;
    color: #ffffff;
}




.btn6 {
    border: 1px solid #cccccc;
    padding: 5px 10px;
    font-size: 12px;
    cursor: pointer;
    touch-action: manipulation;
    color: #000;
    border-radius: 3px;
}


.btn6:hover {
    background-color: #eeeeee;
    color: #000;

}

.btn7 {
    border: 1px solid #2c72b0;
    padding: 5px 10px;
    font-size: 12px;
    cursor: pointer;
    touch-action: manipulation;
    color: #fff;
    border-radius: 3px;
    background-color: #2c72b0;
}
</style>
  