<template>
    <div class="wrapper">
        <div class="header">
            <div style="display: flex;margin-top: 2px;border-radius:3px;overflow: hidden;border: 1px solid #cccccc;">
                <div @click="tabClick(index)" v-for="(item, index) in tab" :key="index"
                    :class="{ 'tab': true, 'tab-select': index === selected, 'tab-border-left': index != 0 }">
                    {{ item.name }}</div>
            </div>
            <div style="display: flex;margin-top: 2px;">
                <div class="mybtn" style="margin-right: 10px;" @click="createGoods()"><span
                        style="font-weight: 600;">＋</span>
                    创建项目
                </div>
                <div class="mybtn" @click="categoryManage()">分类管理</div>
            </div>

        </div>
        <div class="body">
            <b-table class="table table-bordered table-sm" style="vertical-align: middle;font-size: 14px;"
                :items="goodsList" :fields="fields">
                <template v-slot:cell(selectedName)="data">
                    <div style="font-weight: 600;font-size: 15px;height: 100%;">{{ data.value }}</div>
                </template>
                <template v-slot:cell(name)="data">
                    <div style="font-weight: 600;font-size: 15px;height: 100%;">{{ data.value }}</div>
                </template>
                <template v-slot:cell(fee)="data">
                    <div style="font-size: 12px;"><span
                            style="color: red;font-size: 15px;">{{ data.value /100}}</span>元/次
                    </div>
                </template>
                <template v-slot:cell(operate)="data">
                    <div style="display: flex;align-items: center;justify-content: center;">
                        <div @click="editGoods(data.item)"
                            style="color: #aaaaaa;display: flex;flex-direction: column;align-items: center;justify-content: center;cursor: pointer;width: 32px;margin-left: 10px;">
                            <img src="../../assets/edit.png" style="width: 22px;height: 22px;display: inline-block;"
                                alt="">
                            <span style="font-size: 13px;">修改</span>
                        </div>
                        <div @click="deleteGoods(data.item)"
                            style="color: #aaaaaa;display: flex;flex-direction: column;align-items: center;justify-content: center;cursor: pointer;width: 32px;margin-left: 10px;">
                            <img src="../../assets/delete2.png" style="width: 22px;height: 22px;display: inline-block;"
                                alt="">
                            <span style="font-size: 13px;">删除</span>
                        </div>
                    </div>
                </template>
            </b-table>

            <template>
                <div>
                    <!-- 分类管理 -->
                    <Modal ref="categoryManage" @confirm="categoryManage_submit" :footer="false">
                        <template v-slot:item="">
                            <div style="font-weight: 600;font-size: 14px;padding: 0 35px;">
                                <div style="display: flex;align-items: center;margin: 5px 0;">
                                    <div style="width: 100px;">创建分类</div>
                                    <b-input style="height: 33px;border-radius: 3px 0 0 3px;font-size: 14px;"
                                        type="text" placeholder="分类名称" v-model="createCategory_input_value"></b-input>
                                    <div @click="createCategory()" class="mybtn"
                                        style="border-radius: 0 3px 3px 0 ;height: 33px;min-width: 58px;">
                                        创建
                                    </div>
                                </div>
                                <div v-for="(item, index) in category" :key="index"
                                    style="display: flex;align-items: center;box-sizing: border-box;">
                                    <div style="display: flex;align-items: center;width: 100%;flex:1;">
                                        <b-input disabled :value="item.category_id"
                                            style="width: 65px;font-size: 11px;border-radius: 3px 0 0 3px;padding: 5px 10px;height: 33px;">
                                        </b-input>
                                        <b-input :disabled="!item.isEdit" v-model="item.name" key="tel"
                                            style="width: 100%;flex:1;font-size: 11px;border-radius: 0;padding: 5px 10px;height: 33px;">
                                        </b-input>
                                        <div v-if="!item.isEdit" style="display: flex;align-items: center;">
                                            <div @click="editCategory(index)" class="mybtn"
                                                style="border-radius: 0;height: 33px;min-width: 58px;display: flex;align-items: center;justify-content: center;">
                                                编辑
                                            </div>
                                            <div class="btn5" @click="deleteCategory(index)"
                                                style="border-radius: 0 3px 3px 0;height: 33px;display: flex;align-items: center;justify-content: center;min-width: 58px;">
                                                删除</div>
                                        </div>
                                        <div v-else style="display: flex;align-items: center;">
                                            <div @click="editCategory_save(index)" class="mybtn"
                                                style="border-radius: 0;height: 30px;min-width: 58px;">
                                                保存
                                            </div>
                                            <div class="btn5" @click="cancelEdit_save(index)"
                                                style="border-radius: 0 3px 3px 0;height: 30px;display: flex;align-items: center;justify-content: center;min-width: 58px;">
                                                取消</div>
                                        </div>
                                    </div>

                                </div>
                            </div>
                        </template>
                    </Modal>


                    <!-- 创建项目 -->
                    <b-modal ref="myModal3" id="modal3" title="创建项目" ok-title="确定" cancel-title="不取消" footer="true">
                        <div style="font-weight: 600;font-size: 14px;padding: 0 35px;line-height: 50px;">
                            <div style="display: flex;align-items: center;">
                                <span style="margin-right: 8px;width: 100px;text-align: right;"><span
                                        style="color:red;margin-right: 5px;">*</span>项目名称</span>
                                <b-input v-model="createGoodsParams.selectedName" style="width: 200px;font-size: 14px;">
                                </b-input>
                            </div>
                            <div style="display: flex;align-items: center;">
                                <span style="margin-right: 8px;width: 100px;text-align: right;"><span
                                        style="color:red;margin-right: 5px;">*</span>选项名称</span>
                                <b-input v-model="createGoodsParams.name" style="width: 200px;font-size: 14px;">
                                </b-input>
                            </div>
                            <div style="display: flex;align-items: center;">
                                <span style="margin-right: 8px;width: 100px;text-align: right;"><span
                                        style="color:red;margin-right: 5px;">*</span>分类ID</span>
                                <b-input v-model="createGoodsParams.category_id" style="width: 200px;font-size: 14px;">
                                </b-input>
                            </div>
                            <div style="display: flex;align-items: center;">
                                <span style="margin-right: 8px;;width: 100px;text-align: right;"><span
                                        style="color:red;margin-right: 5px;">*</span>销售价格</span>
                                <div class="input-group" style="width: 200px;">
                                    <input style="width: 88px;font-size: 14px;" type="text" class="form-control"
                                        @keyup="priceInputKeyup" v-model="createGoodsParams.price" placeholder="">
                                    <div class="input-group-append">
                                        <span class="input-group-text" style="width: 88px;font-size: 14px;"
                                            id="basic-addon2">元/次</span>
                                    </div>
                                </div>
                            </div>

                        </div>
                        <template #modal-footer>
                            <div @click="hideModal3" class="btn3">取消</div>
                            <div @click="createGoods_submit" class="mybtn" style=" padding: 8px 17px;font-size: 14px;">
                                确定</div>
                        </template>
                    </b-modal>


                    <Modal ref="modal_editGoods" @confirm="editGoods_submit"></Modal>
                    <Modal ref="modal_createGoods" @confirm="createGoods_submit"></Modal>

                    <Modal ref="deleteGoods" @confirm="deleteGoods_submit"></Modal>
                </div>
            </template>
        </div>
    </div>
</template>
<script>
    import {
        BModal
    } from 'bootstrap-vue'
    import Modal from '../../components/Modal/Modal'
    export default {
        name: 'Program',
        components: {
            'b-modal': BModal,
            Modal
        },
        data() {
            return {
                items: {},
                goodsList: [],
                category: [],
                category_copy: [],
                bread_crumb_selected: 0,
                breadCrumb: [{
                        name: '微信/支付宝项目'
                    },
                    {
                        name: '抖音项目'
                    },
                ],
                fields: [{
                        key: 'goods_name',
                        label: '项目名称',
                    },
                    {
                        key: 'goods_title',
                        label: '项目标题',
                    },
                    {
                        key: 'fee',
                        label: '价格/单位',
                    },
                    {
                        key: 'sales',
                        label: '销量',
                        width: '100px'
                    },
                    {
                        key: 'operate',
                        label: '操作',
                    }
                ],
                tab: [{
                    name: '全部项目',
                    category_id: 0
                }, {
                    name: '开锁'
                }, {
                    name: '智能锁'
                }, {
                    name: '汽车锁'
                }],
                selected: 0,
                createCategory_input_value: '',
                createGoodsParams: {},
                deleteGoodsParam: {
                    goods_id: null
                }
                // showModal:false
            };
        },
        mounted() {
            this.getGoodsList()
        },
        methods: {
            tabClick(index) {
                this.selected = index
                this.getGoodsList()
            },
            tabBreadCrumb(index) {
                this.bread_crumb_selected = index
                // if (index == 0) {
                //     this.business_cooperating_get()
                // } else if (index == 1) {
                //     this.businesses_info_get()
                // } else if (index == 2) {
                //     this.businesses_application_get()
                // } else if (index == 3) {
                //     this.merchantData = {}
                //     setTimeout(() => {
                //         if (this.bread_crumb_selected == 3) {
                //             this.showAmap_merchant()
                //         }
                //     }, 500);
                // }
            },
            getGoodsList() {
                this.goodsList = [];
                this.showLoading = true;
                let category_id = this.tab[this.selected].category_id;
                this.post('dy_goodsList_get', {
                    category_id
                }).then(({
                    goodsList,
                    category
                }) => {
                    console.log('获取订单列表', goodsList, category)
                    category.unshift(this.tab[0])
                    this.tab = category;
                    goodsList.forEach(element => {
                        element.operate = ''
                        element.price = element.price / 100
                    });
                    setTimeout(() => {
                        if (category_id !== this.tab[this.selected].category_id) return false;
                        this.goodsList = goodsList
                        this.showLoading = false
                    }, 100);
                })
            },
            getCategory() {
                return new Promise((resolve, reject) => {
                    this.category = [];
                    this.post('dy_cateGory_get', {}).then(({
                        category
                    }) => {
                        console.log('获取分类列表', category)
                        category.forEach(element => {
                            element.isEdit = false
                        })
                        console.log('xxs', category)
                        this.category_copy = JSON.parse(JSON.stringify(category))
                        this.category = category
                        resolve(category)
                    })
                })
            },
            async editGoods(item) {
                const category = await this.getCategory();
                const options = category.map(item => {
                    return {
                        text: item.name,
                        value: item.category_id
                    }
                })

                this.$refs.modal_editGoods.show({
                    type: 'form',
                    title: '修改项目',
                    data: [{
                            name: 'goods_id',
                            label: '编码',
                            value: item.goods_id,
                            required: true,
                            disabled: true
                        }, {
                            name: 'category_id',
                            label: '类目',
                            type: 'select',
                            options: options,
                            showAll: false,
                            showSearch: false,
                            value: item.category_id,
                            placeholder: '',
                            required: true
                        },
                        {
                            name: 'goods_name',
                            label: '项目简称',
                            value: item.goods_name,
                            placeholder: '',
                            required: true,
                            disabled: false
                        },
                        {
                            name: 'thumbs_url',
                            label: '主图',
                            type: 'images',
                            value: item.thumbs_url,
                            placeholder: '',
                            required: true
                        },
                        {
                            name: 'goods_title',
                            label: '标题',
                            value: item.goods_title,
                            placeholder: '',
                            required: true
                        },
                        {
                            name: 'details_url',
                            label: '详情图',
                            type: 'images',
                            value: item.details_url,
                            placeholder: '',
                            required: true
                        },
                        {
                            name: 'price',
                            label: '销售价格',
                            value: item.fee / 100,
                            placeholder: '',
                            required: true
                        },
                        {
                            name: 'sales',
                            label: '销量',
                            value: item.sales,
                            placeholder: '',
                            default: 0,
                            required: true,
                            disabled: false
                        },
                    ]
                })
            },
            editGoods_submit(data) {
                console.log('编辑项目数据', data)
                data.category_id = Number(data.category_id);
                data.goods_id = Number(data.goods_id);
                data.fee = data.price * 100;
                data.thumbs_url = data.thumbs_url.filter(item => item && item.src)
                data.details_url = data.details_url.filter(item => item && item.src)
                if (data.thumbs_url.length == 0) {
                    return this.$toast({
                        title: '请上传项目主图'
                    })
                }
                if (data.details_url.length == 0) {
                    return this.$toast({
                        title: '请上传详情图'
                    })
                }
                this.post('dy_goods_edit', {
                    ...data
                }).then(res => {
                    this.$toast({
                        title: '修改成功'
                    })
                    this.getGoodsList()
                })
            },
            async createGoods() {
                const category = await this.getCategory();
                const options = category.map(item => {
                    return {
                        text: item.name,
                        value: item.category_id
                    }
                })
                this.$refs.modal_createGoods.show({
                    type: 'form',
                    title: '创建项目',
                    data: [{
                            name: 'category_id',
                            label: '类目',
                            type: 'select',
                            options: options,
                            showAll: false,
                            showSearch: false,
                            value: null,
                            placeholder: '',
                            required: true
                        },
                        {
                            name: 'goods_name',
                            label: '项目简称',
                            value: null,
                            placeholder: '',
                            required: true,
                            disabled: false
                        },
                        {
                            name: 'thumbs_url',
                            label: '主图',
                            type: 'images',
                            value: [],
                            placeholder: '',
                            required: true
                        },
                        {
                            name: 'goods_title',
                            label: '标题',
                            value: null,
                            placeholder: '',
                            required: true
                        },

                        {
                            name: 'details_url',
                            label: '详情图',
                            type: 'images',
                            value: [],
                            placeholder: '',
                            required: true
                        },
                        {
                            name: 'price',
                            label: '销售价格',
                            value: null,
                            placeholder: '',
                            required: true
                        },
                        {
                            name: 'sales',
                            label: '销量',
                            value: 0,
                            placeholder: '',
                            required: true
                        },
                    ]
                })
            },
            createGoods_submit(data) {
                console.log('创建项目数据', data)
                data.thumbs_url = data.thumbs_url.filter(item => item && item.src)
                data.details_url = data.details_url.filter(item => item && item.src)
                if (data.thumbs_url.length == 0) {
                    return this.$toast({
                        title: '请上传项目主图'
                    })
                }
                if (data.details_url.length == 0) {
                    return this.$toast({
                        title: '请上传详情图'
                    })
                }

                data.category_id = Number(data.category_id);
                data.fee = data.price * 100;
                this.post('dy_goods_create', {
                    ...data
                }).then(res => {
                    this.$toast({
                        title: '创建成功'
                    })
                    this.getGoodsList()
                })

            },


            hideModal3() {
                this.$refs.myModal3.hide()
            },
            categoryManage() {
                this.getCategory()
                this.$refs.categoryManage.show({
                    type: 'form',
                    title: '分类管理',
                    data: []
                });
                // this.showModal = true 
            },
            categoryManage_submit() {
                this.$refs.myModal2.hide()
            },

            hideModal4() {
                this.$refs.myModal4.hide()
            },
            submitEditGoods() {

            },
            priceInputKeyup(e) {
                console.log(e.target.value.replace(/[^\d]/g, ''))
                let filter = e.target.value.replace(/[^\d]/g, '');
                this.items.price = filter;
                console.log(this.items.price)
            },
            editCategory(index) {
                this.category[index].isEdit = true
                console.log(this.category[index])
            },
            editCategory_save(index) {
                console.log('发送的数据：', {
                    item: this.category[index]
                })
                this.category[index].category_id = Number(this.category[index].category_id);
                this.post('dy_category_edit', {
                    item: this.category[index]
                }).then((res) => {
                    Object.assign(this.category_copy[index], this.category[index])
                    this.category[index].isEdit = false
                    this.$toast({
                        title: '修改成功'
                    })
                    this.getGoodsList()
                })
            },
            cancelEdit_save(index) {
                this.category[index].isEdit = false
                Object.assign(this.category[index], this.category_copy[index])
            },
            deleteCategory(index) {
                this.post('dy_category_delete', {
                    item: this.category[index]
                }).then((res) => {
                    this.category.splice(index, 1)
                    this.$toast({
                        title: '删除成功'
                    })
                    this.tabClick(0)
                })
            },
            createCategory() {
                let name = this.createCategory_input_value
                this.post('dy_category_create', {
                    name
                }).then((new_category) => {
                    this.$toast({
                        title: '创建成功'
                    })
                    this.tabClick(0)
                    this.getCategory()
                    this.showModal2()
                })
            },

            deleteGoods(item) {
                console.log('删除', item)
                this.$refs.deleteGoods.show({
                    type: 'query',
                    title: '删除合作商家',
                    content: '是否确认删除？',
                    data: [{
                        name: 'goods_id',
                        label: '编码',
                        value: item.goods_id,
                        required: true,
                        disabled: true
                    }]
                })
            },

            deleteGoods_submit(item) {
                console.log('删除提交的数据', item)
                this.post('dy_goods_delete', {
                    goods_id: item.goods_id
                }).then(res => {
                    this.$toast({
                        title: '操作成功'
                    })
                    this.getGoodsList()
                })
            },


        }
    }
</script>
<style scoped>
    .wrapper {
        color: #333;
        background-color: #fff;
        border-radius: 3px;
        border: 1px solid #dddddd;
    }

    .header {
        background-color: #f5f5f5;
        border-bottom: 1px solid #dddddd;
        border-top-left-radius: 3px;
        border-top-right-radius: 3px;
        padding: 10px 15px;
        font-size: 12.5px;
        font-family: "Microsoft YaHei", SimSun;
        display: flex;
        justify-content: space-between;
    }

    .body {
        padding: 15px 15px;
    }

    .tab {
        background-color: white;
        padding: 5px 10px;
        font-size: 11.5px;
        cursor: pointer;
        touch-action: manipulation;
    }

    .tab-select {
        color: white;
        background-color: #1f68be;
    }

    .tab:hover {
        background: #e6e6e6;
        color: black;
    }

    .tab:active {
        background: #e6e6e6;
        color: black;
        box-shadow: inset 0 3px 5px rgba(0, 0, 0, .125);
    }

    .tab-border-left {
        border-left: 1px solid #cccccc;
    }

    .mybtn {
        border-radius: 3px;
        background-color: #206bc4;
        padding: 5px 10px;
        font-size: 11.5px;
        cursor: pointer;
        touch-action: manipulation;
        color: #ffffff;
    }

    .mybtn:hover {
        background-color: #286090;
        color: #ffffff;
    }


    .btn3 {
        background-color: #f0ad4e;
        padding: 8px 17px;
        font-size: 14px;
        cursor: pointer;
        touch-action: manipulation;
        color: #ffffff;
        border-radius: 3px;
    }

    .btn3:hover {
        background-color: #e09b3c;
        color: #ffffff;
    }



    .btn5 {
        background-color: #d9534f;
        padding: 5px 10px;
        font-size: 12px;
        cursor: pointer;
        touch-action: manipulation;
        color: #ffffff;
    }

    .btn5:hover {
        background-color: #c9302c;
        color: #ffffff;
    }
</style>
<style>
    .table th,
    .table td {
        padding: 0.25rem !important;
        vertical-align: middle !important;
        border-top: 1px solid #dee2e6 !important;
    }

    .table th {
        height: 40px;
    }

    .bread_crumb_item {
        cursor: pointer;
        border-bottom: 2px solid #fff;
        padding: 10px 15px 8px 15px;
        color: #206bc4;
        font-size: 14px;
        margin-right: 4px;

    }

    .bread_crumb_item_selected {
        cursor: pointer;
        border-bottom: 2px solid #206bc4;
        padding: 10px 15px 8px 15px;
        border-radius: 5px 5px 0 0;
        color: #fff;
        background: #206bc4;
        font-size: 14px;
        margin-right: 4px;

    }

    .bread_crumb_item_selected:hover {
        background: #206bc4;
    }

    .bread_crumb_item:hover {
        background: #eeeeee;
    }
</style>