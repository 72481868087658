<template>
    <div v-if="data.length > 0" style="width: 100%;border: 1px solid #dee2e6;">
        <!-- header -->
        <div ref="tHeader" style="display: flex;width: 100%;" :style="tHeaderStyle">
            <div style="width: 100%;padding: 10px;font-weight: 600;display: flex;align-items: center;justify-content: center;font-size: 14px;"
                v-for="(item, index) in fields" :id="tid + '-' + item.key" :key="index"
                :style="{ 'border-left': index !== 0 && tHeaderItemBorder == true ? '1px solid #dee2e6' : 'none', 'max-width': item.width, 'min-width': item.minWidth||0, 'font-size': tHeaderStyle['font-size'] || '14px' }">
                {{ item.label }}
            </div>
        </div>
        <!-- body -->
        <div style="border-top: 1px solid #dee2e6;" :style="tBodyStyle">
            <div v-for="(item, index) in data" :key="index" v-show="!item.hide" style="box-sizing: border-box;"
                :style="{ 'border-top': index != 0 ? '1px solid #dee2e6' : '', ...RowStyle, 'font-size': tBodyStyle['font-size'] || '14px', }">
                <slot name="header" :item="item" style="width: 100%;border-top:1px solid #dee2e6;box-sizing: border-box;">
                </slot>
                <div style="display: flex;width: 100%;max-height: 200px;" :style="{ 'border-top': index !== 0 ? '1px solid #dee2e6;' : '' }">
                    <div style="width: 100%;padding: 10px;box-sizing: border-box;word-break:break-all;display: flex;align-items: center;justify-content: center;"
                        v-for="(fields_item, fields_index) in fields"
                        :style="{ 'border-left': fields_index !== 0 && tBodyItemBorder == true ? '1px solid #dee2e6' : 'none', 'max-width': fields_item.width, }"
                        :key="fields_index">
                        <slot :name="fields_item.key" :item="item" :value="item[fields_item.key]">{{ item[fields_item.key]
                        }}
                        </slot>
                    </div>
                </div>
                <slot name="footer" :item="item" style="width: 100%;border-top:1px solid #dee2e6;box-sizing: border-box;">
                </slot>
            </div>

        </div>
        <!-- loading -->
        <div v-if="loading&&!loadAll"
            style="display: flex;align-items: center;justify-content: center;padding: 20px 0;background-color: #fff;height: 88px;">
            <img style="margin-right: 5px;" src="./images/loading.gif" alt="">加载中..
        </div>
    </div>
</template>
<script>
export default {
    props: {
        fields: {
            type: Array,
            default: []
        },
        data: {
            type: Array,
            default: []
        },
        searchValue: {
            type: String,
            default: ''
        },
        searchFields: {
            type: Array,
            default: []
        },
        tHeaderItemBorder: {
            type: Boolean,
            default: true
        },
        tBodyItemBorder: {
            type: Boolean,
            default: true
        },
        tHeaderStyle: {
            type: Object,
            default: {}
        },
        tBodyStyle: {
            type: Object,
            default: {}
        },
        RowStyle: {
            type: Object,
            default: {}
        },
        loadAll: {
            type: Boolean,
            default: true
        }
    },
    data() {
        return {
            tid: 'tid' + Math.floor(Math.random() * 100000000),
            loading: false
        }
    },
    mounted() {
        console.log('Table mount')
        window.removeEventListener('scroll', () => this.scrollHandler())
        if (this.loadAll === false) {
            console.log('非全部加载')
            window.addEventListener('scroll', () => this.scrollHandler())
        } else {
            console.log('全部加载')
        }
        let tHeader = this.$refs.tHeader
        let tHeaderChildren = tHeader.children
        for (let i = 0; i < tHeaderChildren.length; i++) {
            const item = tHeaderChildren[i];
            const width = item.clientWidth;
            this.fields[i].width = width + 'px'
        }
    },
    watch: {
        data(newValue) {
            console.log('数据改变')
            this.loading = false
        }
    },
    methods: {
        scrollHandler() {
            if (this.loadAll === false) {
                const windowHeight = window.innerHeight
                const scrollTop = document.documentElement.scrollTop || document.body.scrollTop
                const scrollHeight = document.documentElement.scrollHeight || document.body.scrollHeight
                // console.log({ windowHeight, scrollTop, scrollHeight })
                // console.log((windowHeight + scrollTop) >= (scrollHeight - 50), !this.loading)
                if (!this.loading) {
                    if ((windowHeight + scrollTop) >= (scrollHeight - 88)) {
                        this.loading = true
                        this.$emit('scroll', null)
                    }
                    if ((windowHeight + scrollTop) >= scrollHeight) {
                        this.$emit('scroll', null)
                        console.log('到底部')
                    }
                }
            } else {
                this.loading = false
            }
        },
        showLoading() {
            console.log('显示触发')
            this.loading = true
        },
        hideLoading() {
            this.loading = false
        },
        search({ value, fields, force = {} }) {
            console.log('开始搜索', force)
            this.data = this.data.map(element => {
                if (force.value && force.fields) {
                    if (!element[force.fields] || element[force.fields].indexOf(force.value) == -1) {
                        element.hide = true
                        return element
                    }
                }
                for (let i in fields) {
                    if (element[fields[i]] && element[fields[i]].indexOf(value) !== -1) {
                        element.hide = false
                        count++
                        return element
                    }
                }

                element.hide = true
                return element
            })
        },
        reset() {
            this.data = this.data.map(element => {
                element.hide = false
                return element
            })
        }
    }
}
</script>